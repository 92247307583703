import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToggleLeft, ToggleRight } from 'lucide-react';
import { DateTime } from 'luxon';

import { type GetWorkingTimeOutputDataType } from '@/shared/api/graphql/members/validators';
import { RoutePaths } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';
import { getEndDateTime } from '@/hooks/states/useWorkingTimeStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import RefreshIcon from '@/components/icons/RefreshIcon';
import Portal from '@/components/Portal';

type Props = {
  workingTime: GetWorkingTimeOutputDataType;
  disabled?: boolean;
  onUpdateAsNow?: (workingTime: GetWorkingTimeOutputDataType) => Promise<void>;
  onDelete?: (workingTime: GetWorkingTimeOutputDataType) => Promise<void>;
  onAutoRefresh?: (workingTime: GetWorkingTimeOutputDataType) => Promise<void>;
  onEndTimeDisplay?: (workingTime: GetWorkingTimeOutputDataType) => Promise<void>;
};

const WorkingTimeCard = ({
  workingTime,
  disabled,
  onUpdateAsNow,
  onDelete,
  onAutoRefresh,
  onEndTimeDisplay,
}: Props) => {
  // Hooks.

  const [requestDelete, setRequestDelete] = useState(false);

  const navigate = useNavigate();

  const { push } = usePopupStore();

  // Computed.

  const endTime = getEndDateTime(workingTime)?.toFormat('HH:mm');

  const updatedAt = DateTime.fromISO(workingTime.updatedAt).toFormat('yyyy-MM:dd, T');

  const updatedAtDateTime = DateTime.fromISO(workingTime.updatedAt);
  const comparedWithNow = updatedAtDateTime.endOf('day').diffNow().toMillis();
  const isTodayUpdated = comparedWithNow > 0;

  // Listeners.

  /**
   * 'Update' button click event handler.
   */
  const onUpdateClick = () => {
    navigate(RoutePaths.WorkingTimeUpdate);
  };

  /**
   * 'Delete' Button click event handler.
   */
  const onDeleteClick = () => {
    setRequestDelete(true);
  };

  /**
   * 'Auto refresh' button click event handler.
   */
  const onAutoRefreshToggle = () => {
    onAutoRefresh?.(workingTime);
  };

  /**
   * 'End time display' button click event handler.
   */
  const onEndTimeDisplayToggle = () => {
    onEndTimeDisplay?.(workingTime);
  };

  /**
   * 'Update as now' button click event handler.
   */
  const onUpdateAsNowClick = () => {
    onUpdateAsNow?.(workingTime);
  };

  return (
    <>
      <article key={workingTime.id} className="space-y-4">
        {/* Total working time */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">총 근무 시간</small>
          <div className="flex items-center gap-2">
            <p className="tracking-widest text-sm">{workingTime.totalTime}</p>
            <span className="text-gray-500 text-xs">시간</span>
          </div>
        </section>

        {/* Break time */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">휴식 시간</small>
          <div className="flex items-center gap-2">
            <p className="tracking-widest text-sm">{workingTime.breakTime}</p>
            <span className="text-gray-500 text-xs">시간</span>
          </div>
        </section>

        {/* Start time */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">출근 시간</small>
          <p className="tracking-widest text-sm">{workingTime.startTime}</p>
        </section>

        {/* End time */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">퇴근 시간</small>
          <p className="tracking-widest text-sm">{endTime}</p>
        </section>

        {/* Auto refresh status */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">자동 업데이트 설정</small>
          <button
            className="flex items-center gap-2 disabled:cursor-not-allowed"
            onClick={onAutoRefreshToggle}
            disabled={disabled}
          >
            {workingTime.isAutoRefresh ? <ToggleRight /> : <ToggleLeft />}
            <p className="tracking-widest text-sm">{workingTime.isAutoRefresh ? '켜짐' : '꺼짐'}</p>
          </button>
        </section>

        {/* Displaying closing time status */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">퇴근 시간 표시 여부</small>
          <button
            className="flex items-center gap-2 disabled:cursor-not-allowed"
            onClick={onEndTimeDisplayToggle}
            disabled={disabled}
          >
            {workingTime.isDisplayEndTime ? <ToggleRight /> : <ToggleLeft />}
            <p className="tracking-widest text-sm">
              {workingTime.isDisplayEndTime ? '켜짐' : '꺼짐'}
            </p>
          </button>
        </section>

        {/* Auto refresh status */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">오늘 업데이트 여부</small>
          <p className="tracking-widest text-sm">{isTodayUpdated ? '완료' : '미완료'}</p>
        </section>

        {/* Created at */}
        <section className="flex items-center justify-between">
          <small className="text-gray-500">설정일</small>
          <p className="tracking-widest text-sm">{updatedAt}</p>
        </section>

        <section className="flex items-center justify-between gap-4 *:text-xs">
          <Button
            className="flex items-center justify-center flex-1 py-2"
            title="변경하기"
            disabled={disabled}
            onClick={onUpdateClick}
          >
            <EditIcon />
          </Button>

          <Button
            className="flex items-center justify-center flex-1 py-2"
            title="현재 시간으로 재적용"
            disabled={disabled}
            onClick={onUpdateAsNowClick}
          >
            <RefreshIcon />
          </Button>

          <Button
            className="flex items-center justify-center flex-1 py-2"
            title="삭제하기"
            disabled={disabled}
            onClick={onDeleteClick}
          >
            <DeleteIcon />
          </Button>
        </section>
      </article>

      <Portal portalId="modal">
        {requestDelete ? (
          <ConfirmModal
            title="근무 시간 삭제"
            content="정말 근무 시간을 삭제하시겠어요?"
            onConfirm={async () => {
              await onDelete?.(workingTime);

              setRequestDelete(false);

              push({ title: '근무 시간 삭제', subtitle: '정상적으로 삭제되었습니다.' });
            }}
            onClose={() => setRequestDelete(false)}
            onCancel={() => setRequestDelete(false)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default WorkingTimeCard;
