import { Link } from 'react-router-dom';

import { FoldVertical, SquareArrowOutUpRight, Trash } from 'lucide-react';

import { GetWorksOutputDataType } from '@/shared/api/graphql/works/validators/get-works.validator';
import { UNKNOWN } from '@/shared/constants';
import { RoutePaths } from '@/shared/constants/routes';

type Props = {
  items?: GetWorksOutputDataType;
  disabled?: boolean;
  onDeleteClick?: (id: number) => void;
};

const WorkItems = ({ items, disabled, onDeleteClick }: Props) => {
  if (!items?.length) {
    return (
      <small className="text-red-500 animate-pulse">
        데이터가 없습니다. 먼저 작업을 생성해주세요.
      </small>
    );
  }

  return (
    <ul className="dynamic-grid">
      {items.map((item) => (
        <li
          key={item.id}
          className="border-pixel divide-y divide-gray-500 flex flex-col justify-between"
        >
          <small className="text-xs tracking-widest p-1 text-center">
            {item.project?.name ?? UNKNOWN}
          </small>
          <details className="space-y-2 p-2 flex-1" open>
            <summary className="cursor-pointer flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <FoldVertical className="animate-pulse" size={16} />
                <h3 className="pl-1 truncate text-sm" title={item.name}>
                  {item.name}
                </h3>
                <small>({item.reports.length})</small>
              </div>
            </summary>

            {item.reports.length ? (
              <ul className="max-h-24 overflow-y-auto">
                {item.reports.map((report) => (
                  <li key={report.id}>
                    <Link
                      className="flex items-center gap-2"
                      to={`${RoutePaths.DailyReportUpdate}?id=${report.id}`}
                    >
                      <small className="underline">{report.content}</small>
                      <SquareArrowOutUpRight size={13} />
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <small className="text-xs text-gray-500">리포트가 존재하지 않습니다.</small>
            )}
          </details>

          <button
            className="w-full flex items-center justify-center py-2 hover:bg-red-500 disabled:cursor-not-allowed"
            disabled={disabled}
            onClick={() => onDeleteClick?.(item.id)}
          >
            <Trash className="stroke-gray-600" size={16} />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default WorkItems;
