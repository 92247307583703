import WorkingTimeCard from '@/features/working-times/WorkingTimeCard';
import WorkingTimeEditForm from '@/features/working-times/WorkingTimeEditForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import {
  deleteWorkingTime,
  getWorkingTime,
  setWorkingTime,
} from '@/shared/api/graphql/members/services';
import { GetWorkingTimeOutputDataType } from '@/shared/api/graphql/members/validators';
import { WorkingTimeForm } from '@/shared/api/graphql/members/validators/working-time.validator';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import QuestionIcon from '@/components/icons/QuestionIcon';
import Loader from '@/components/Loader';

const WORKING_TIME_QUERY_KEY = 'workingTime';

const WorkingTime = () => {
  const { push } = usePopupStore();

  const queryClient = useQueryClient();

  // Get working time query.
  const { data: workingTimeData, isLoading: workingTimeIsLoading } = useQuery({
    queryKey: [WORKING_TIME_QUERY_KEY],
    queryFn: getWorkingTime,
  });

  // Set working time mutation.
  const { mutateAsync: setWorkingTimeMutate, isPending: setWorkingTimeIsPending } = useMutation({
    mutationFn: setWorkingTime,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [WORKING_TIME_QUERY_KEY] });

      push({ title: '근무 시간 추가', subtitle: '정상적으로 추가되었습니다.' });
    },
    onError: (error) => {
      push({ title: '근무 시간 추가', subtitle: error.message, level: 'error' });
    },
  });

  // delete working time mutation.
  const { mutateAsync: deleteWorkingTimeMutate, isPending: deleteWorkingTimeIsPending } =
    useMutation({
      mutationFn: deleteWorkingTime,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [WORKING_TIME_QUERY_KEY] });

        push({ title: '근무 시간 삭제', subtitle: '정상적으로 삭제되었습니다.' });
      },
      onError: (error) => {
        push({ title: '근무 시간 삭제', subtitle: error.message, level: 'error' });
      },
    });

  const onSubmit = (form: WorkingTimeForm) => {
    setWorkingTimeMutate({
      ...form,
      totalTime: Number(form.totalTime),
      breakTime: Number(form.breakTime),
    });
  };

  /**
   * On delete event handler.
   */
  const onDelete = async (workingTime: GetWorkingTimeOutputDataType) => {
    deleteWorkingTimeMutate({ id: workingTime.id });
  };

  /**
   * 'Auto refresh' button toggle event handler.
   */
  const onAutoRefresh = async (workingTime: GetWorkingTimeOutputDataType) => {
    const { isAutoRefresh, ...rest } = workingTime;

    setWorkingTimeMutate({ ...rest, isAutoRefresh: !isAutoRefresh });
  };

  /**
   * 'End time display' button toggle event hnadler.
   */
  const onEndTimeDisplay = async (workingTime: GetWorkingTimeOutputDataType) => {
    const { isDisplayEndTime, ...rest } = workingTime;

    setWorkingTimeMutate({ ...rest, isDisplayEndTime: !isDisplayEndTime });
  };

  /**
   * 'Update as now' button click event handler.
   */
  const onUpdateAsNow = async (workingTime: GetWorkingTimeOutputDataType) => {
    setWorkingTimeMutate({ ...workingTime, startTime: DateTime.now().toFormat('HH:mm') });
  };

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between gap-5">
          <section className="flex items-center gap-5">
            <h1 className="font-bold tracking-wider">근무 시간 관리</h1>
          </section>
        </div>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs space-y-4">
            근무 시간을 설정하고, 남은 퇴근 시간을 한 눈에 확인하세요.
          </p>
        </div>
      </section>

      <section>
        {workingTimeIsLoading ? (
          <Loader />
        ) : workingTimeData ? (
          <WorkingTimeCard
            workingTime={workingTimeData}
            disabled={deleteWorkingTimeIsPending || setWorkingTimeIsPending}
            onDelete={onDelete}
            onAutoRefresh={onAutoRefresh}
            onEndTimeDisplay={onEndTimeDisplay}
            onUpdateAsNow={onUpdateAsNow}
          />
        ) : (
          <WorkingTimeEditForm disabled={setWorkingTimeIsPending} onSubmit={onSubmit} />
        )}
      </section>
    </>
  );
};

export default WorkingTime;
