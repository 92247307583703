import { Link } from 'react-router-dom';

import AccountListItems from '@/features/accounts/AccountListItems';
import { useQuery } from '@tanstack/react-query';

import { getAccounts } from '@/shared/api/graphql/accounts/services/get-accounts.service';
import { RoutePaths } from '@/shared/constants/routes';

import Button from '@/components/Button';
import QuestionIcon from '@/components/icons/QuestionIcon';
import Loader from '@/components/Loader';

const AccountList = () => {
  const { data: accountsData, isLoading: accountsIsLoading } = useQuery({
    queryKey: ['accounts'],
    queryFn: getAccounts,
  });

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <h1 className="font-bold tracking-wider">계정 정보 목록</h1>
          </div>

          <Link className="no-link" to={RoutePaths.AccountCreate}>
            <Button>추가</Button>
          </Link>
        </div>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs">
            새로운 계정을 추가하고, 잊어버리지 않도록 관리하세요!
          </p>
        </div>
      </section>

      <section>
        {accountsIsLoading ? <Loader /> : <AccountListItems accounts={accountsData} />}
      </section>
    </>
  );
};

export default AccountList;
