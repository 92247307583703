import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import DailyReportItems from '@/features/reports/DailyReportItems';
import DailyReportTimeline from '@/features/reports/DailyReportTimeline';
import DailyReportTypes from '@/features/reports/DailyReportTypes';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { z } from 'zod';

import { getDailyReports } from '@/shared/api/graphql/reports/services/get-daily-reports.service';
import {
  DAILY_REPORT_VIEW_TEXT,
  DAILY_REPORT_VIEW_TYPE,
} from '@/shared/constants/reports/daily-report.constant';
import { RoutePaths } from '@/shared/constants/routes';
import { debouncer } from '@/shared/libs/debouncer.lib';

import Button from '@/components/Button';
import CalendarDayInput, { CalendarDate } from '@/components/inputs/CalendarDayInput';
import SelectInput from '@/components/inputs/SelectInput';
import Loader from '@/components/Loader';

export type DailyReportViewType = keyof typeof DAILY_REPORT_VIEW_TYPE;

export const reportListFormSchema = z.object({
  viewType: z.enum([DAILY_REPORT_VIEW_TYPE.CARD, DAILY_REPORT_VIEW_TYPE.TIMELINE]),
});

export type ReportListFormType = z.infer<typeof reportListFormSchema>;

const DailyReportList = () => {
  const now = DateTime.now();

  // Hooks.

  // States.
  const calendarState = useState<CalendarDate>({
    year: now.year,
    month: now.month,
    day: now.day,
  });
  const [calendarDate] = calendarState;

  const parsedMonth = String(calendarDate.month).padStart(2, '0');
  const parsedDay = String(calendarDate.day).padStart(2, '0');
  const fullDate = `${calendarDate.year}-${parsedMonth}-${parsedDay}`;

  const [debouncedDate, setDebouncedDate] = useState(fullDate);

  // Forms.
  const { watch, register } = useForm<ReportListFormType>({
    defaultValues: {
      viewType: DAILY_REPORT_VIEW_TYPE.TIMELINE,
    },
  });
  const viewTypeWatched = watch('viewType');

  // Get daily reports query.
  const { data: dailyReports, isLoading: dailyReportsIsLoading } = useQuery({
    queryKey: ['dailyReports', debouncedDate],
    queryFn: () => getDailyReports({ date: debouncedDate }),
  });

  // Functions.

  async function refreshDate() {
    if (!fullDate) return;

    const debounced = await debouncer(() => fullDate);
    setDebouncedDate(debounced);
  }

  // Effects.

  useEffect(() => {
    refreshDate();
  }, [fullDate]);

  return (
    <>
      {/* Daily report types */}
      <section className="space-y-3">
        <h1 className="font-bold tracking-wider">보고서 타입</h1>
        <DailyReportTypes />
      </section>

      {/* Daily report list */}
      <section className="space-y-3">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3">
          <div className="flex items-center justify-between gap-3">
            <h1 className="font-bold tracking-wider">일일 보고서</h1>

            <Link className="w-20 sm:hidden no-link" to={RoutePaths.DailyReportCreate}>
              <Button className="w-full">추가</Button>
            </Link>
          </div>
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center justify-end">
              <SelectInput
                wrapperClassName="h-[38px] text-sm"
                register={register('viewType')}
                name="input-view-type"
                options={[
                  {
                    label: DAILY_REPORT_VIEW_TEXT[DAILY_REPORT_VIEW_TYPE.TIMELINE],
                    value: DAILY_REPORT_VIEW_TYPE.TIMELINE,
                  },
                  {
                    label: DAILY_REPORT_VIEW_TEXT[DAILY_REPORT_VIEW_TYPE.CARD],
                    value: DAILY_REPORT_VIEW_TYPE.CARD,
                  },
                ]}
              />
            </div>

            <div className="flex items-center gap-3 shrink-0">
              <CalendarDayInput className="flex-1" calendarState={calendarState} />

              <Link className="hidden sm:block w-20 no-link" to={RoutePaths.DailyReportCreate}>
                <Button className="w-full py-2">추가</Button>
              </Link>
            </div>
          </div>
        </div>

        {dailyReportsIsLoading ? (
          <Loader />
        ) : viewTypeWatched === DAILY_REPORT_VIEW_TYPE.TIMELINE ? (
          <DailyReportTimeline fullDate={fullDate} reports={dailyReports ?? []} />
        ) : (
          <DailyReportItems date={fullDate} />
        )}
      </section>
    </>
  );
};

export default DailyReportList;
