import { Suspense, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import PomodoroTimerDynamicButton from '@/features/pomodoro/PomodoroTimerDynamicButton';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { createFocus } from '@/shared/api/graphql/focus/services/create-focus.service';
import {
  FocusEditFormSchema,
  FocusEditFormType,
} from '@/shared/api/graphql/focus/validators/create-focus.validator';
import { createDailyReport } from '@/shared/api/graphql/reports/services/create-daily-report.service';
import { getTasks } from '@/shared/api/graphql/tasks/services/get-tasks.service';
import Tomato from '@/shared/assets/images/tomato.png';
import timerDoneSound from '@/shared/assets/sounds/timer_done.wav';
import {
  POMODORO_BREAKING_STATUS,
  POMODORO_WORKING_STATUS,
  type PomodoroStatusType,
} from '@/shared/constants';
import { RoutePaths } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';
import useTimer from '@/hooks/useTimer';

import Button from '@/components/Button';
import CircleTimer from '@/components/CircleTimer';
import QuestionIcon from '@/components/icons/QuestionIcon';
import SelectInput from '@/components/inputs/SelectInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import Loader from '@/components/Loader';

// export const DEFAULT_POMODORO_MINUTES = 5;

const PomodoroTimer = () => {
  const TIMER_MINUTES_OFFSET = 1;
  const DEFAULT_TIMER_MINUTES = 5;

  // Hook: Ref
  const audioRef = useRef<HTMLAudioElement>(null);

  // Hook: Pomodoro timer status.
  const [status, setStatus] = useState<PomodoroStatusType>(POMODORO_WORKING_STATUS.WORKING_IDLE);
  const [defaultTimerMinutes, setDefaultTimerMinutes] = useState(DEFAULT_TIMER_MINUTES);

  const navigate = useNavigate();

  const isStarted =
    status === POMODORO_WORKING_STATUS.WORKING_RUNNING ||
    status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING;

  // Hook: For timer.
  const {
    currentMilliseconds,
    targetMilliseconds,
    seconds,
    formattedTime,
    startedAt,
    endedAt,
    reset: timerReset,
    setTimer,
  } = useTimer({
    minutes: defaultTimerMinutes,
    // seconds: 10,
    isStarted,
  });

  const { push } = usePopupStore();

  const {
    register,
    watch,
    getValues,
    formState: { errors },
    reset: formReset,
  } = useForm<FocusEditFormType>({
    resolver: zodResolver(FocusEditFormSchema),
    defaultValues: {
      taskId: '',
      memo: '',
    },
  });

  const watchedTaskId = watch('taskId');

  const queryClient = useQueryClient();

  // Hook: Getting task list query.
  const { data: tasksData } = useQuery({
    queryKey: ['tasks'],
    queryFn: getTasks,
  });

  // Hook: Create focus mutation.
  const { mutateAsync: createFocusMutate, isPending: createFocusIsPending } = useMutation({
    mutationFn: createFocus,
    onSuccess: () => {
      push({ title: '포커스 생성', subtitle: '정상적으로 저장되었습니다.' });
      queryClient.invalidateQueries({ queryKey: ['focuses'] });
    },
    onError: (error) => {
      push({ title: '포커스 생성', subtitle: error.message, level: 'warning' });
    },
  });

  // Hook: Create daily report mutation.
  const { mutateAsync: createDailyReportMutate, isPending: createDailyReportIsPending } =
    useMutation({
      mutationFn: createDailyReport,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['dailyReports'] });
      },
      onError: (error) => {
        push({ title: '리포트 생성', subtitle: error.message, level: 'warning' });
      },
    });

  // Effect: Detect task ID value.
  useEffect(() => {
    if (watchedTaskId) {
      const task = tasksData?.find((task) => task.id === Number(watchedTaskId));
      if (!task?.estimatedMinutes) return;

      setTimer({ minutes: task.estimatedMinutes });
    } else {
      setTimer({ minutes: defaultTimerMinutes });
    }
  }, [watchedTaskId]);

  // Effect: Change status as DONE when timer was overed.
  useEffect(() => {
    if (seconds <= 0 && isStarted) {
      setStatus(POMODORO_WORKING_STATUS.WORKING_DONE);
    }
  }, [seconds]);

  // Effect: Detect timer status.
  useEffect(() => {
    executeTimerProcess(status);
  }, [status]);

  /**
   * Execute timer process by status.
   */
  function executeTimerProcess(status: PomodoroStatusType) {
    const startedAtDateTime = startedAt.current;
    const endedAtDateTime = endedAt.current;

    switch (status) {
      case POMODORO_WORKING_STATUS.WORKING_IDLE:
        timerReset();

        formReset();

        break;

      case POMODORO_WORKING_STATUS.WORKING_DONE:
        audioRef.current?.play();

        savePomodoroFocus(startedAtDateTime, endedAtDateTime);

        saveReport(startedAtDateTime, endedAtDateTime);

        timerReset();

        formReset();
        break;
    }
  }

  /**
   * Save focus data.
   */
  async function savePomodoroFocus(startDateTime?: DateTime, endDateTime?: DateTime) {
    if (createFocusIsPending) return;

    const startIso = startDateTime?.toISO();
    const endIso = endDateTime?.toISO();

    if (!startIso || !endIso) {
      push({
        title: '포커스 저장',
        subtitle: '포커스 시작/종료 시간에 오류가 발생했습니다.',
        level: 'error',
      });
      return;
    }

    createFocusMutate({
      startedAt: startIso,
      endedAt: endIso,
      taskId: Number(getValues('taskId')),
      memo: getValues('memo'),
    });
  }

  /**
   * Save report data.
   */
  async function saveReport(startDateTime?: DateTime, endDateTime?: DateTime) {
    if (createDailyReportIsPending) return;
    const startedAtTime = startDateTime?.toFormat('HH:mm');
    const endedAtTime = endDateTime?.toFormat('HH:mm');

    if (!startedAtTime || !endedAtTime) {
      push({
        title: '리포트 저장',
        subtitle: '포커스 시작/종료 시간에 오류가 발생했습니다.',
        level: 'error',
      });
      return;
    }

    createDailyReportMutate({
      date: DateTime.now().toFormat('yyyy-MM-dd'),
      startTime: startedAtTime,
      endTime: endedAtTime,
      content: getValues('memo') || '포커스',
    });
  }

  /**
   * 'Timer status' value change event handler.
   */
  const onTimerStatusChange = (changedStatus: PomodoroStatusType) => {
    setStatus(changedStatus);
  };

  /**
   * 'Plus or Minus' button click event handler.
   */
  const onTimeChangeClick = (isPlus: boolean) => {
    const currentMinutes = Math.floor(currentMilliseconds / 1000 / 60);
    const defaultMinutes = isPlus
      ? currentMinutes + TIMER_MINUTES_OFFSET
      : currentMinutes - TIMER_MINUTES_OFFSET;

    setDefaultTimerMinutes(defaultMinutes);
    setTimer({ minutes: defaultMinutes });
  };

  return (
    <>
      <section className="space-y-4">
        <section className="flex items-center justify-between gap-5">
          <h1 className="font-bold tracking-wider">포모도로 타이머</h1>

          <section className="flex items-center gap-5">
            <Button
              disabled={status !== POMODORO_WORKING_STATUS.WORKING_IDLE}
              onClick={() => {
                navigate(RoutePaths.PomodoroStatistics);
              }}
            >
              통계
            </Button>
          </section>
        </section>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs space-y-4">
            포모도로 타이머와 함께 효율적으로 시간을 관리해보세요.
          </p>
        </div>
      </section>

      {/* Task choosing list */}
      <Suspense fallback={<Loader />}>
        <section>
          <SelectInput
            register={register('taskId')}
            options={
              tasksData
                ?.filter((task) => task.status !== 'DONE')
                .map((task) => ({
                  label: `${task.title} (${task.estimatedMinutes ?? '?'} 분)`,
                  value: task.id,
                })) ?? []
            }
            withNoneOption
            disabled={
              status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING ||
              status === POMODORO_BREAKING_STATUS.BREAKING_DONE ||
              status === POMODORO_WORKING_STATUS.WORKING_DONE
            }
          />
        </section>
      </Suspense>

      {/* Timer */}
      <section>
        {status === POMODORO_WORKING_STATUS.WORKING_DONE ? (
          <div className="flex flex-col items-center gap-4 my-10">
            <div className="relative size-48 *:size-48 *:absolute *:left-0 *:top-0 *:bg-transparent *:pointer-events-none">
              <img className="animate-pulse" src={Tomato} alt="Tomato" />
              <img className="animate-ping" src={Tomato} alt="Tomato" />
            </div>

            <h1 className="text-xl tracking-wider">성공했어요!</h1>
            <span>5분간 휴식을 취해보세요~!</span>
          </div>
        ) : (
          <>
            <CircleTimer
              status={status}
              currentMilliseconds={currentMilliseconds}
              targetMilliseconds={targetMilliseconds}
              minutes={formattedTime?.minutes}
              seconds={formattedTime?.seconds}
              isPaused={!isStarted}
              onMinusClick={() => onTimeChangeClick(false)}
              onPlusClick={() => onTimeChangeClick(true)}
            />
          </>
        )}
      </section>

      {/* Dynamic action button */}
      <section className="flex gap-2 justify-between *:grow">
        <PomodoroTimerDynamicButton
          status={status}
          milliseconds={currentMilliseconds}
          onChange={onTimerStatusChange}
        />
      </section>

      {/* Memo */}
      <section>
        <TextAreaInput
          className="text-sm tracking-wider"
          placeholder="메모를 입력하세요"
          register={register('memo')}
          disabled={
            status === POMODORO_WORKING_STATUS.WORKING_DONE ||
            status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING ||
            status === POMODORO_BREAKING_STATUS.BREAKING_DONE
          }
          error={errors.memo?.message}
          watch={watch}
          maxLength={100}
          rows={4}
        />
      </section>

      {/* Etc */}
      <audio ref={audioRef} src={timerDoneSound} />
    </>
  );
};

export default PomodoroTimer;
