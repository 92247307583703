import { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';

import MeetingBlackIcon from '@/shared/assets/icons/meeting_icon_black.png';
import MeetingWhiteIcon from '@/shared/assets/icons/meeting_icon_white.png';
import ScheduleBlackIcon from '@/shared/assets/icons/schedule_icon_black.png';
import ScheduleWhiteIcon from '@/shared/assets/icons/schedule_icon_white.png';
import TodoBlackIcon from '@/shared/assets/icons/todo_icon_3_black.png';
import TodoWhiteIcon from '@/shared/assets/icons/todo_icon_3_white.png';
import { RoutePaths } from '@/shared/constants/routes';
import { cn } from '@/shared/libs/style.lib';

import { useAuthStore } from '@/hooks/states/useAuthStore';
import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import Avatar from '@/components/Avatar';
import UsersIcon from '@/components/icons/UsersIcon';

type Props = ComponentProps<'nav'>;

// Common class name of each of them.
const ICON_CLASS_NAME = 'transition group-hover:scale-125 dark-ignore light-ignore';

const routes = [
  {
    title: '일일 리포트',
    to: RoutePaths.DailyReportList,
    icon: { black: ScheduleBlackIcon, white: ScheduleWhiteIcon },
  },
  {
    title: '예정 작업',
    to: RoutePaths.TaskList,
    icon: { black: TodoBlackIcon, white: TodoWhiteIcon },
  },
  {
    title: '회의',
    to: RoutePaths.MeetingList,
    icon: { black: MeetingBlackIcon, white: MeetingWhiteIcon },
  },
  // {
  //   title: '내 정보',
  //   to: RoutePaths.MyProfile,
  //   icon: <User className={cn(ICON_CLASS_NAME)} />,
  // },
] as const;

const HorizontalMenuNav = ({ ...rest }: Props) => {
  const { isDark } = useDarkModeStore();

  const { me } = useAuthStore();

  return (
    <nav className="w-full" {...rest}>
      <ul className="grid grid-cols-4 h-14 divide-x divide-gray-300">
        {routes.map((route) => (
          <li title={route.title} key={route.to}>
            <NavLink
              to={route.to}
              className={({ isActive }) =>
                cn(
                  'group w-full h-full flex items-center justify-center hover:bg-gray-200 cursor-pointer dark-ignore light-ignore',
                  { [isDark ? 'bg-gray-600' : 'bg-gray-300']: isActive },
                )
              }
            >
              <img
                className="size-6 bg-transparent"
                src={isDark ? route.icon.white : route.icon.black}
                alt={route.title}
                loading="lazy"
              />
            </NavLink>
          </li>
        ))}

        <li title="내 정보">
          <NavLink
            to={RoutePaths.MyProfile}
            className={({ isActive }) =>
              cn(
                'group w-full h-full flex items-center justify-center hover:bg-gray-200 cursor-pointer dark-ignore light-ignore',
                { [isDark ? 'bg-gray-600' : 'bg-gray-300']: isActive },
              )
            }
          >
            {me?.avatarUrl ? (
              <Avatar className="size-6" url={me.avatarUrl} />
            ) : (
              <UsersIcon className={cn(ICON_CLASS_NAME)} />
            )}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default HorizontalMenuNav;
