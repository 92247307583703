import {
  POMODORO_BREAKING_STATUS,
  POMODORO_WORKING_STATUS,
  PomodoroStatusType,
} from '@/shared/constants';
import { cn } from '@/shared/libs/style.lib';

import Button from '@/components/Button';

type Props = {
  status: PomodoroStatusType;
  milliseconds: number;
  onChange?: (status: PomodoroStatusType) => void;
};

const PomodoroTimerDynamicButton = ({ status, milliseconds, onChange }: Props) => {
  return (
    <>
      {/* Working time */}

      {/* 시작 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_IDLE,
        })}
        disabled={milliseconds <= 0}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_RUNNING)}
      >
        시작
      </Button>

      {/* 일시 정지 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_RUNNING,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_PAUSE)}
      >
        일시 정지
      </Button>

      {/* 계속 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_PAUSE,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_RUNNING)}
      >
        계속
      </Button>

      {/* 종료 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_PAUSE,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_IDLE)}
      >
        종료
      </Button>

      {/* 완료 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_PAUSE,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_DONE)}
      >
        완료
      </Button>

      {/* 휴식 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_DONE,
        })}
        onClick={() => onChange?.(POMODORO_BREAKING_STATUS.BREAKING_RUNNING)}
      >
        휴식
      </Button>

      {/* 건너뛰기 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_DONE,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_RUNNING)}
      >
        건너뛰기
      </Button>

      {/* 종료 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_WORKING_STATUS.WORKING_DONE,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_IDLE)}
      >
        종료
      </Button>

      {/* Breaking time */}

      {/* 건너뛰기 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_RUNNING)}
      >
        건너뛰기
      </Button>

      {/* 종료 */}
      <Button
        className={cn('hidden', {
          block: status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING,
        })}
        onClick={() => onChange?.(POMODORO_WORKING_STATUS.WORKING_IDLE)}
      >
        종료
      </Button>
    </>

    // <>
    //   <Button
    //     className={cn('hidden bg-green-500 hover:bg-green-400 text-white', {
    //       block: status === POMODORO_STATUS.IDLE,
    //     })}
    //     onClick={() => onChange?.(POMODORO_STATUS.RUNNING)}
    //     disabled={milliseconds <= 0}
    //   >
    //     시작
    //   </Button>

    //   <Button
    //     className={cn('hidden bg-orange-500 hover:bg-orange-400 text-white', {
    //       block: status === POMODORO_STATUS.RUNNING,
    //     })}
    //     onClick={() => onChange?.(POMODORO_STATUS.PAUSE)}
    //   >
    //     일시 중지
    //   </Button>

    //   <Button
    //     className={cn('hidden bg-indigo-500 hover:bg-indigo-400 text-white', {
    //       block: status === POMODORO_STATUS.DONE,
    //     })}
    //     onClick={() => onChange?.(POMODORO_STATUS.BREAKING)}
    //   >
    //     휴식
    //   </Button>

    //   <Button
    //     className={cn('hidden bg-emerald-500 hover:bg-emerald-400 text-white', {
    //       block:
    //         status === POMODORO_STATUS.DONE ||
    //         status === POMODORO_STATUS.PAUSE ||
    //         status === POMODORO_STATUS.BREAKING ||
    //         status === POMODORO_STATUS.BREAK_DONE,
    //     })}
    //     onClick={() => onChange?.(POMODORO_STATUS.RUNNING)}
    //   >
    //     계속
    //   </Button>

    //   <Button
    //     className={cn('hidden bg-red-500 hover:bg-red-400 text-white', {
    //       block:
    //         status === POMODORO_STATUS.DONE ||
    //         status === POMODORO_STATUS.PAUSE ||
    //         status === POMODORO_STATUS.BREAKING ||
    //         status === POMODORO_STATUS.BREAK_DONE,
    //     })}
    //     onClick={() => onChange?.(POMODORO_STATUS.DONE)}
    //   >
    //     종료
    //   </Button>
    // </>
  );
};

export default PomodoroTimerDynamicButton;
