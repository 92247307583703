import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { deleteMeeting } from '@/shared/api/graphql/meetings/services/delete-meeting.service';
import { GetMeetingsOutputDataType } from '@/shared/api/graphql/meetings/validators/get-meetings.validator';
import { PRIORITY_VALUE, type PriorityValueType } from '@/shared/constants';
import {
  MEETING_TYPE_VALUE,
  type MeetingTypeValueKeys,
} from '@/shared/constants/meetings/meeting.constant';
import { RoutePaths } from '@/shared/constants/routes';
import { getTimeZone } from '@/shared/helpers/date.helper';
import { cn } from '@/shared/libs/style.lib';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import ConfirmModal from '@/components/ConfirmModal';
import CalendarIcon from '@/components/icons/CalendarIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import GeoLocationIcon from '@/components/icons/GeoLocationIcon';
import LevelIcon from '@/components/icons/LevelIcon';
import RepeatIcon from '@/components/icons/RepeatIcon';
import RingIcon from '@/components/icons/RingIcon';
import TypeIcon from '@/components/icons/TypeIcon';
import UsersIcon from '@/components/icons/UsersIcon';
import Portal from '@/components/Portal';

type Props = {
  meeting: GetMeetingsOutputDataType;
};

const MeetingCard = ({ meeting }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { push } = usePopupStore();

  const [deletingMeetingId, setDeletingMeetingId] = useState<number | undefined>(undefined);

  // Remove meeting mutation.
  const { mutateAsync: deleteMeetingMutate, isPending: deleteMeetingIsPending } = useMutation({
    mutationFn: deleteMeeting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meetings'] });

      push({ title: '회의 삭제 성공', subtitle: '회의가 정상적으로 삭제되었습니다.' });
    },
    onError: (error) => {
      push({
        title: '회의 삭제 실패',
        subtitle: error.message ?? '회의 삭제를 실패했습니다. 잠시 후 다시 시도해주세요.',
      });
    },
  });

  const isLoading = deleteMeetingIsPending;

  /**
   * Decode 'type' value as text from encoded.
   */
  const translateType = (type: MeetingTypeValueKeys) => {
    switch (type) {
      case MEETING_TYPE_VALUE.IN_PERSON:
        return '대면 회의';

      case MEETING_TYPE_VALUE.VIRTUAL:
        return '가상 회의';

      case MEETING_TYPE_VALUE.HYBRID:
        return '혼합 회의';

      default:
        return '-';
    }
  };

  /**
   * Decode 'priority' value as text from encoded.
   */
  const translatePriority = (priority: PriorityValueType) => {
    switch (priority) {
      case PRIORITY_VALUE.HIGH:
        return '높음';

      case PRIORITY_VALUE.MIDDLE:
        return '중간';

      case PRIORITY_VALUE.LOW:
        return '낮음';

      default:
        return '-';
    }
  };

  const meetingDate = () =>
    DateTime.fromISO(meeting.dateTime).setZone(getTimeZone()).toFormat('yyyy-MM-dd');

  const meetingTime = () =>
    DateTime.fromISO(meeting.dateTime).setZone(getTimeZone()).toFormat('HH:mm');
  /**
   * On 'edit' button click event handler.
   */
  const onEditClick = () => {
    if (isLoading) return;

    // Move on to edit page.
    navigate(`${RoutePaths.MeetingList}/${meeting.id}`);
  };

  /**
   * On 'remove' button click event handler.
   */
  const onRemoveClick = () => {
    if (isLoading) return;

    // Setting meeting id for displaying confirm modal.
    setDeletingMeetingId(meeting.id);
  };

  return (
    <>
      <article
        className={cn('space-y-4 p-4 border-pixel rounded-sm divide-y divide-gray-400', {
          'bg-gray-200 animate-pulse': deleteMeetingIsPending,
        })}
      >
        <section className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-4">
            {/* Type */}
            <div className="flex items-center gap-2" title="회의 형태">
              <TypeIcon />
              <small className="tracking-wider text-gray-400">{translateType(meeting.type)}</small>
            </div>

            {/* Priority */}
            <div className="flex items-center gap-2" title="중요도">
              <LevelIcon />
              <small className="tracking-wider text-gray-400">
                {translatePriority(meeting.priority)}
              </small>
            </div>

            {/* Notification status */}
            {meeting.useNotification && (
              <div title="알림 설정">
                <RingIcon status="VIBRATE" />
              </div>
            )}

            {/* Recurring status */}
            {meeting.useRecurring && (
              <div title="반복 설정">
                <RepeatIcon />
              </div>
            )}
          </div>

          {/* Actions */}
          <ul className="flex items-center gap-4 *:cursor-pointer">
            <li title="해당 회의 수정하기">
              <EditIcon alt="Edit button icon" onClick={onEditClick} />
            </li>
            <li title="해당 회의 삭제하기">
              <DeleteIcon alt="Edit button icon" onClick={onRemoveClick} />
            </li>
          </ul>
        </section>

        <section className="space-y-2 pt-4">
          <section className="space-y-4">
            {/* Title */}
            <h1 className="text-2xl font-semibold" title="회의 제목">
              {meeting.title}
            </h1>

            {/* Location */}
            <div className="flex items-center gap-4" title="회의 장소">
              <GeoLocationIcon />
              <p className="tracking-wider text-gray-600">{meeting.location}</p>
            </div>
          </section>
        </section>

        {/* Datetime */}
        <section className="flex items-center pt-4" title="회의 날짜 및 시간">
          <CalendarIcon className="mr-4" />

          <div className="flex text-gray-400 divide-x-2 text-sm tracking-wider">
            {/* Date */}
            <div className="pr-2">{meetingDate()}</div>

            {/* Time */}
            <div className="pl-2">{meetingTime()}</div>
          </div>
        </section>

        {/* Participants */}
        <section className="flex items-center pt-4" title="회의 참석자">
          <UsersIcon className="self-start mt-1 mr-4" />

          <ul className="flex items-center gap-2 flex-wrap">
            {meeting.participants.map((participant) => (
              <li
                key={participant.id}
                className="text-xs border-pixel border-gray-400 rounded-sm px-2 py-1"
              >
                {participant.name}
              </li>
            ))}
          </ul>
        </section>
      </article>

      <Portal portalId="modal">
        {deletingMeetingId ? (
          <ConfirmModal
            title="회의 삭제"
            content="정말 해당 회의를 삭제하시겠어요?"
            isPending={deleteMeetingIsPending}
            onConfirm={async () => {
              await deleteMeetingMutate({ id: deletingMeetingId });
              setDeletingMeetingId(undefined);
            }}
            onClose={() => setDeletingMeetingId(undefined)}
            onCancel={() => setDeletingMeetingId(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default MeetingCard;
