import { useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';

import { millisecondsToRelativeTime } from '@/shared/helpers';

import type { RemainingTimeType, TimeType, UseTimerInput } from './use-timer.validator';

const useTimer = (input: UseTimerInput) => {
  const { minutes = 0, seconds = 0, isStarted = false } = input;

  const targetMilliseconds = useRef(0);
  const startedAt = useRef<DateTime>();
  const endedAt = useRef<DateTime>();

  const [currentMilliseconds, setCurrentMilliseconds] = useState(0);
  const [formattedTime, setFormattedTime] = useState<RemainingTimeType>();

  /**
   * Reset timer by initialized values.
   */
  const reset = () => {
    const __seconds__ = minutes * 60 + seconds;
    const __milliseconds__ = __seconds__ * 1000;

    setCurrentMilliseconds(__milliseconds__);
    targetMilliseconds.current = __milliseconds__;
  };

  /**
   * Set timer by time values.
   */
  const setTimer = ({ minutes = 0, seconds = 0 }: TimeType) => {
    const __seconds__ = Math.max(minutes * 60 + seconds, 0);
    const __milliseconds__ = __seconds__ * 1000;

    setCurrentMilliseconds(__milliseconds__);
    targetMilliseconds.current = __milliseconds__;
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    // Set started at value.
    if (isStarted && targetMilliseconds.current - currentMilliseconds === 0) {
      startedAt.current = DateTime.now();
    }

    // Passing time.
    const intervalId = setInterval(() => {
      if (isStarted) {
        setCurrentMilliseconds((curr) => (curr > 0 ? curr - 1000 : 0));

        endedAt.current = DateTime.now();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isStarted]);

  // Compute remaining time.
  useEffect(() => {
    if (currentMilliseconds < 0) return;

    const { minutes, seconds } = millisecondsToRelativeTime(currentMilliseconds);

    setFormattedTime({ minutes, seconds });
  }, [currentMilliseconds]);

  return {
    currentMilliseconds,
    targetMilliseconds: targetMilliseconds.current,
    seconds: currentMilliseconds / 1000,
    minutes: currentMilliseconds / 1000 / 60,
    formattedTime,
    reset,
    setTimer,
    startedAt,
    endedAt,
  };
};

export default useTimer;
