import { useNavigate } from 'react-router-dom';

import { GetProjectsOutputDataType } from '@/shared/api/graphql/projects/validators/get-projects.validator';
import { RoutePaths } from '@/shared/constants/routes';

import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import FoldVerticalIcon from '@/components/icons/FoldVerticalIcon';

type Props = {
  items?: GetProjectsOutputDataType[];
  disabled?: boolean;
  onDeleteClick?: (id: number) => void;
};

const ProjectItems = ({ items, disabled, onDeleteClick }: Props) => {
  const navigate = useNavigate();

  if (!items?.length) {
    return (
      <small className="text-red-500 animate-pulse">
        데이터가 없습니다. 먼저 프로젝트를 생성해주세요.
      </small>
    );
  }

  const onEditClick = (projectId: number) => {
    navigate(`${RoutePaths.ProjectUpdate}?id=${projectId}`);
  };

  return (
    <ul className="dynamic-grid">
      {items.map((item) => (
        <li
          key={item.id}
          className="border-pixel divide-y divide-gray-500 flex flex-col justify-between"
        >
          <details className="space-y-2 p-2" open>
            <summary className="cursor-pointer flex items-center gap-2">
              <FoldVerticalIcon className="animate-pulse" />
              <h3 className="pl-1 truncate text-sm" title={item.name}>
                {item.name}
              </h3>
              <small>({item.works.length})</small>
            </summary>

            {item.works.length ? (
              <ul className="max-h-24 overflow-y-auto">
                {item.works.map((work) => (
                  <li key={work.id} className="flex items-center gap-2">
                    <small>{work.name}</small>
                  </li>
                ))}
              </ul>
            ) : (
              <small className="text-xs text-gray-500">작업이 존재하지 않습니다.</small>
            )}
          </details>

          <div className="flex items-center divide-x divide-black">
            <button
              className="flex-1 flex items-center justify-center py-2 hover:bg-orange-400 disabled:cursor-not-allowed"
              onClick={() => onEditClick(item.id)}
            >
              <EditIcon />
            </button>
            <button
              className="flex-1 flex items-center justify-center py-2 hover:bg-red-500 disabled:cursor-not-allowed"
              disabled={disabled}
              onClick={() => onDeleteClick?.(item.id)}
            >
              <DeleteIcon />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ProjectItems;
