/**
 * Convert milliseconds to formatted relative time.
 */
export const millisecondsToRelativeTime = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = Math.max(0, Math.floor(seconds / 60));
  const remainedSeconds = Math.max(0, seconds % 60);

  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(remainedSeconds).padStart(2, '0'),
  };
};
