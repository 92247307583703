import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';

import { GetWorkingTimeOutputDataType } from '@/shared/api/graphql/members/validators';
import {
  WorkingTimeForm,
  workingTimeForm,
} from '@/shared/api/graphql/members/validators/working-time.validator';

import Button from '@/components/Button';
import HelpText from '@/components/HelpText';
import SaveIcon from '@/components/icons/SaveIcon';
import CheckBoxInput from '@/components/inputs/CheckBoxInput';
import TextInput from '@/components/inputs/TextInput';

export const DEFAULT_TOTAL_TIME = 8;
export const DEFAULT_BREAK_TIME = 1;

type Props = {
  workingTime?: GetWorkingTimeOutputDataType;
  disabled?: boolean;
  onSubmit?: (form: WorkingTimeForm) => void;
};

const WorkingTimeEditForm = ({ workingTime, disabled, onSubmit }: Props) => {
  // const { setWorkingTime, workingTime } = useWorkingTimeStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkingTimeForm>({
    resolver: zodResolver(workingTimeForm),
    defaultValues: {
      startTime: workingTime?.startTime ?? DateTime.now().toFormat('HH:mm'),
      totalTime: (workingTime?.totalTime ?? DEFAULT_TOTAL_TIME) + '',
      breakTime: (workingTime?.breakTime ?? DEFAULT_BREAK_TIME) + '',
      isAutoRefresh: workingTime?.isAutoRefresh ?? false,
      isDisplayEndTime: workingTime?.isDisplayEndTime ?? false,
    },
  });

  const onSubmitValid = (form: WorkingTimeForm) => {
    onSubmit?.(form);
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmitValid)}>
      <TextInput
        register={register('startTime')}
        error={errors.startTime?.message}
        id="input-start"
        placeholder="10:30"
        type="time"
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500">출근 시간</p>
          <HelpText>출근 시간을 입력하세요.</HelpText>
        </div>
      </TextInput>

      <TextInput
        register={register('totalTime')}
        error={errors.totalTime?.message}
        id="input-total-time"
        placeholder="8"
        type="number"
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500">총 근무 시간</p>
          <HelpText>총 근무 시간을 입력하세요.</HelpText>
        </div>
      </TextInput>

      <TextInput
        register={register('breakTime')}
        error={errors.breakTime?.message}
        id="input-break-time"
        placeholder="1"
        type="number"
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500">휴식 시간</p>
          <HelpText>휴식 시간을 입력하세요.</HelpText>
        </div>
      </TextInput>

      <CheckBoxInput register={register('isAutoRefresh')} id="input-auto-refresh" text="ON / OFF">
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500">자동 새로고침 여부</p>
          <HelpText>매일 자동으로 앱을 실행시킨 시간을 기준으로 근무 시간을 설정합니다.</HelpText>
        </div>
      </CheckBoxInput>

      <CheckBoxInput
        register={register('isDisplayEndTime')}
        id="input-displaying-end-time"
        text="ON / OFF"
      >
        <div className="flex justify-between items-center gap-2">
          <p className="text-xs text-gray-500">퇴근 시간 표시 여부</p>
          <HelpText>앱 상단 헤더에 남은 퇴근 시간을 표시합니다.</HelpText>
        </div>
      </CheckBoxInput>

      <Button
        className="w-full flex items-center justify-center py-2"
        title="저장하기"
        disabled={disabled}
      >
        <SaveIcon />
      </Button>
    </form>
  );
};

export default WorkingTimeEditForm;
