import MeetingCard from '@/features/meetings/MeetingCard';

import type { GetMeetingsOutputDataType } from '@/shared/api/graphql/meetings/validators/get-meetings.validator';
import { RoutePaths } from '@/shared/constants/routes';

import EmptyListGuider from '@/components/EmptyListGuider';

type Props = {
  meetings?: GetMeetingsOutputDataType[];
};

const MeetingListItems = ({ meetings }: Props) => {
  // There are no meetings.

  return meetings?.length ? (
    <ul className="dynamic-grid md:grid-cols-2 lg:grid-cols-4">
      {meetings.map((meeting) => (
        <MeetingCard key={meeting.id} meeting={meeting} />
      ))}
    </ul>
  ) : (
    <EmptyListGuider
      title="아직 등록된 회의가 없네요.."
      subtitle="새로운 회의를 생성해볼까요?"
      path={RoutePaths.MeetingCreate}
    />
  );
};

export default MeetingListItems;
