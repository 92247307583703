export const POMODORO_STATUS = {
  IDLE: 'IDLE',
  RUNNING: 'RUNNING',
  PAUSE: 'PAUSE',
  DONE: 'DONE',
  BREAKING: 'BREAKING',
  BREAK_DONE: 'BREAK_DONE',
} as const;

export const POMODORO_STATUS_TEXT = {
  WORKING_IDLE: '대기중',
  WORKING_RUNNING: '진행중',
  WORKING_PAUSE: '일시 정지',
  WORKING_DONE: '완료',
  BREAKING_RUNNING: '휴식',
  BREAKING_DONE: '휴식 완료',
} as const;

export const POMODORO_WORKING_STATUS = {
  WORKING_IDLE: 'WORKING_IDLE',
  WORKING_RUNNING: 'WORKING_RUNNING',
  WORKING_PAUSE: 'WORKING_PAUSE',
  WORKING_DONE: 'WORKING_DONE',
} as const;

export type PomodoroWorkingStatusType = keyof typeof POMODORO_WORKING_STATUS;

export const POMODORO_BREAKING_STATUS = {
  BREAKING_RUNNING: 'BREAKING_RUNNING',
  BREAKING_DONE: 'BREAKING_DONE',
} as const;

export type PomodoroBreakingStatusType = keyof typeof POMODORO_BREAKING_STATUS;

export type PomodoroStatusType = PomodoroWorkingStatusType | PomodoroBreakingStatusType;
