import { useEffect, useRef } from 'react';

import {
  POMODORO_BREAKING_STATUS,
  POMODORO_STATUS_TEXT,
  POMODORO_WORKING_STATUS,
  type PomodoroStatusType,
} from '@/shared/constants';
import { cn } from '@/shared/libs/style.lib';

type Props = {
  minutes?: string;
  seconds?: string;
  currentMilliseconds?: number;
  targetMilliseconds?: number;
  isPaused?: boolean;
  status: PomodoroStatusType;
  onPlusClick?: () => void;
  onMinusClick?: () => void;
};

const CircleTimer = ({
  currentMilliseconds = 0,
  targetMilliseconds = 0,
  minutes,
  seconds,
  status,
  isPaused,
  onPlusClick,
  onMinusClick,
}: Props) => {
  const circleRef = useRef<SVGCircleElement>(null);
  const STROKE_DASH_VALUE = 1231.504320207199;

  /**
   * Set timer value, when milliseconds value changed.
   */
  useEffect(() => {
    if (!circleRef.current) return;

    const percentageValue = Math.min(1, 1 - currentMilliseconds / targetMilliseconds);
    const nextValue = String(STROKE_DASH_VALUE - STROKE_DASH_VALUE * percentageValue);

    circleRef.current.style.strokeDashoffset = nextValue;
  }, [currentMilliseconds, targetMilliseconds]);

  return (
    <div className="relative flex justify-center items-center">
      {/* Circle area */}
      <div className="flex justify-center items-center">
        <svg className="w-[300px] h-[300px] -rotate-90" viewBox="0 0 400 400">
          <circle cx="200" cy="200" r="196" className="fill-none stroke-green-100 stroke-[8px]" />
          <circle
            ref={circleRef}
            cx="200"
            cy="200"
            r="196"
            className="fill-none stroke-green-500"
            style={{
              strokeWidth: '8px',
              strokeDasharray: STROKE_DASH_VALUE,
              strokeDashoffset: STROKE_DASH_VALUE,
            }}
          />
        </svg>
      </div>

      {/* Center area */}
      <div className="absolute flex flex-col gap-2 items-center justify-center">
        <div className="absolute text-6xl tracking-wider flex">
          <div className="relative">
            {isPaused && (
              <button className="absolute -left-12" onClick={onMinusClick}>
                -
              </button>
            )}

            <span>{minutes}</span>
          </div>

          <span
            className={cn({
              'animate-blink':
                status === POMODORO_WORKING_STATUS.WORKING_RUNNING ||
                status === POMODORO_BREAKING_STATUS.BREAKING_RUNNING,
            })}
          >
            :
          </span>

          <div className="relative">
            <span>{seconds}</span>
            {isPaused && (
              <button className="absolute -right-12" onClick={onPlusClick}>
                +
              </button>
            )}
          </div>
        </div>
        <small className="mt-32 text-lg text-gray-500">{POMODORO_STATUS_TEXT[status]}</small>
      </div>
    </div>
  );
};

export default CircleTimer;
