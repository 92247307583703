// Constants.
export const RoutePaths = {
  // Catch
  NotFound: '*',

  // Auth
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  FindPassword: '/find-password',
  KakaoLoginReady: '/sign-in/oauth/kakao/login/ready',
  KakaoLoginSuccess: '/sign-in/oauth/kakao/login/success',
  KakaoConnectReady: '/sign-in/oauth/kakao/connect/ready',
  KakaoConnectSuccess: '/sign-in/oauth/kakao/connect/success',

  // Home
  Home: '/',

  // Reports
  ReportList: '/reports',
  DailyReportList: '/reports/daily',
  DailyReportCreate: '/reports/daily/new',
  DailyReportUpdate: '/reports/daily/edit',
  WeeklyReportList: '/reports/weekly',
  MonthlyReportList: '/reports/monthly',

  // Tasks
  TaskList: '/tasks',
  TaskCreate: '/tasks/new',
  TaskUpdate: '/tasks/edit',

  // Feedbacks
  FeedbackList: '/feedbacks',
  FeedbackCreate: '/feedbacks/new',

  // Projects
  ProjectList: '/projects',
  ProjectCreate: '/projects/new',
  ProjectUpdate: '/projects/edit',

  // Works
  WorkList: '/works',
  WorkCreate: '/works/new',

  // Meetings
  MeetingList: '/meetings',
  MeetingCreate: '/meetings/new',
  MeetingUpdate: '/meetings/:id',

  // Profile
  MyProfile: '/profile',
  MyProfileUpdate: '/profile/edit',

  // Time stamp
  WorkingTime: '/working-time',
  WorkingTimeUpdate: '/working-time/edit',

  // Accounts
  AccountList: '/accounts',
  AccountCreate: '/accounts/new',
  AccountUpdate: '/accounts/:id',

  // Pomodoro
  PomodoroTimer: '/pomodoro',
  PomodoroStatistics: '/pomodoro/statistics',
} as const;
