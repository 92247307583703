import { lazy, Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { RoutePaths } from '@/shared/constants/routes';

import { useAuthStore } from '@/hooks/states/useAuthStore';

import Body from '@/layouts/Body';
import Footer from '@/layouts/Footer';
import Header from '@/layouts/Header';

import AccountList from '@/pages/accounts/AccountList';
import FeedbackList from '@/pages/feedbacks/FeedbackList';
import MeetingList from '@/pages/meetings/MeetingList';
import PomodoroTimer from '@/pages/pomodoro/PomodoroTimer';
import ProjectList from '@/pages/projects/ProjectList';
import DailyReportList from '@/pages/reports/DailyReportList';
import MonthlyReportList from '@/pages/reports/MonthlyReportList';
import WeeklyReportList from '@/pages/reports/WeeklyReportList';
import TaskList from '@/pages/tasks/TaskList';
import WorkingTime from '@/pages/working-times/WorkingTime';
import WorkList from '@/pages/works/WorkList';

import Loader from '@/components/Loader';
import WakeLockProvider from '@/components/providers/WakeLockProvider';

const PomodoroStatistics = lazy(() => import('@/pages/pomodoro/PomodoroStatistics'));

const NotFound = lazy(() => import('@/pages/others/NotFound'));
const SignIn = lazy(() => import('@/pages/others/SignIn'));
const SignUp = lazy(() => import('@/pages/others/SignUp'));
const FindPassword = lazy(() => import('@/pages/others/FindPassword'));

const KakaoLoginReady = lazy(() => import('@/pages/oauth/KakaoLoginReady'));
const KakaoLoginSuccess = lazy(() => import('@/pages/oauth/KakaoLoginSuccess'));
const KakaoConnectReady = lazy(() => import('@/pages/oauth/KakaoConnectReady'));
const KakaoConnectSuccess = lazy(() => import('@/pages/oauth/KakaoConnectSuccess'));

const AccountCreate = lazy(() => import('@/pages/accounts/AccountCreate'));
const AccountUpdate = lazy(() => import('@/pages/accounts/AccountUpdate'));
const AuthorizedGuard = lazy(() => import('@/components/auth/AuthorizedGuard'));
const Sidebar = lazy(() => import('@/components/Sidebar'));
const FeedbackCreate = lazy(() => import('@/pages/feedbacks/FeedbackCreate'));
const MeetingCreate = lazy(() => import('@/pages/meetings/MeetingCreate'));
const MeetingUpdate = lazy(() => import('@/pages/meetings/MeetingUpdate'));
const MyProfile = lazy(() => import('@/pages/members/MyProfile'));
const MyProfileUpdate = lazy(() => import('@/pages/members/MyProfileUpdate'));
const Home = lazy(() => import('@/pages/others/Home'));
const ProjectCreate = lazy(() => import('@/pages/projects/ProjectCreate'));
const ProjectEdit = lazy(() => import('@/pages/projects/ProjectEdit'));
const DailyReportCreate = lazy(() => import('@/pages/reports/DailyReportCreate'));
const DailyReportUpdate = lazy(() => import('@/pages/reports/DailyReportUpdate'));
const TaskCreate = lazy(() => import('@/pages/tasks/TaskCreate'));
const TaskUpdate = lazy(() => import('@/pages/tasks/TaskUpdate'));
const WorkingTimeUpdate = lazy(() => import('@/pages/working-times/WorkingTimeUpdate'));
const WorkCreate = lazy(() => import('@/pages/works/WorkCreate'));

const AppRouterProvider = () => {
  const { accessToken } = useAuthStore();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Loader className="fixed w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        }
      >
        {accessToken ? <Header /> : null}

        <main className="flex-1 flex items-stretch">
          <Routes>
            <Route path={RoutePaths.NotFound} element={<NotFound />} />
            <Route path={RoutePaths.SignIn} element={<SignIn />} />
            <Route path={RoutePaths.SignUp} element={<SignUp />} />
            <Route path={RoutePaths.FindPassword} element={<FindPassword />} />
            <Route path={RoutePaths.KakaoLoginReady} element={<KakaoLoginReady />} />
            <Route path={RoutePaths.KakaoLoginSuccess} element={<KakaoLoginSuccess />} />
            <Route path={RoutePaths.KakaoConnectReady} element={<KakaoConnectReady />} />
            <Route path={RoutePaths.KakaoConnectSuccess} element={<KakaoConnectSuccess />} />

            <Route
              element={
                <AuthorizedGuard>
                  <Body className="space-y-5">
                    <Outlet />
                  </Body>
                </AuthorizedGuard>
              }
            >
              {/* Others */}
              <Route path={RoutePaths.Home} element={<Home />} />

              {/* Reports */}
              <Route path={RoutePaths.DailyReportList} element={<DailyReportList />} />
              <Route path={RoutePaths.DailyReportCreate} element={<DailyReportCreate />} />
              <Route path={RoutePaths.DailyReportUpdate} element={<DailyReportUpdate />} />
              <Route path={RoutePaths.WeeklyReportList} element={<WeeklyReportList />} />
              <Route path={RoutePaths.MonthlyReportList} element={<MonthlyReportList />} />

              {/* Tasks */}
              <Route path={RoutePaths.TaskList} element={<TaskList />} />
              <Route path={RoutePaths.TaskCreate} element={<TaskCreate />} />
              <Route path={RoutePaths.TaskUpdate} element={<TaskUpdate />} />

              {/* Feedbacks */}
              <Route path={RoutePaths.FeedbackList} element={<FeedbackList />} />
              <Route path={RoutePaths.FeedbackCreate} element={<FeedbackCreate />} />

              {/* Projects */}
              <Route path={RoutePaths.ProjectList} element={<ProjectList />} />
              <Route path={RoutePaths.ProjectCreate} element={<ProjectCreate />} />
              <Route path={RoutePaths.ProjectUpdate} element={<ProjectEdit />} />

              {/* Works */}
              <Route path={RoutePaths.WorkList} element={<WorkList />} />
              <Route path={RoutePaths.WorkCreate} element={<WorkCreate />} />

              {/* Meetings */}
              <Route path={RoutePaths.MeetingList} element={<MeetingList />} />
              <Route path={RoutePaths.MeetingCreate} element={<MeetingCreate />} />
              <Route path={RoutePaths.MeetingUpdate} element={<MeetingUpdate />} />

              {/* Profile */}
              <Route path={RoutePaths.MyProfile} element={<MyProfile />} />
              <Route path={RoutePaths.MyProfileUpdate} element={<MyProfileUpdate />} />

              {/* Working Time */}
              <Route path={RoutePaths.WorkingTime} element={<WorkingTime />} />
              <Route path={RoutePaths.WorkingTimeUpdate} element={<WorkingTimeUpdate />} />

              {/* Accounts */}
              <Route path={RoutePaths.AccountList} element={<AccountList />} />
              <Route path={RoutePaths.AccountCreate} element={<AccountCreate />} />
              <Route path={RoutePaths.AccountUpdate} element={<AccountUpdate />} />

              {/* Pomodoro */}
              <Route
                path={RoutePaths.PomodoroTimer}
                element={
                  <WakeLockProvider>
                    <PomodoroTimer />
                  </WakeLockProvider>
                }
              />
              <Route path={RoutePaths.PomodoroStatistics} element={<PomodoroStatistics />} />
            </Route>
          </Routes>
        </main>

        {accessToken ? <Footer /> : null}
        <Sidebar />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouterProvider;
