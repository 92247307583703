import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteAccount } from '@/shared/api/graphql/accounts/services/delete-account.service';
import { type GetAccountOutputDataType } from '@/shared/api/graphql/accounts/validators/get-account.validator';
import { RoutePaths } from '@/shared/constants/routes';
import { sleep } from '@/shared/helpers';
import { masking, maskingCenter } from '@/shared/helpers/string.helper';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import ConfirmModal from '@/components/ConfirmModal';
import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import IdentifyIcon from '@/components/icons/IdentifyIcon';
import KeyIcon from '@/components/icons/KeyIcon';
import Portal from '@/components/Portal';

type Props = {
  account: GetAccountOutputDataType;
};

const AccountCard = ({ account }: Props) => {
  const EVERY_SECOND = 1000;

  const [deleteAccountId, setDeleteAccountId] = useState<number | undefined>();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { push } = usePopupStore();

  // States.
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isEmailOrNameVisible, setIsEmailOrNameVisible] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);

  const { mutateAsync: deleteAccountMutate, isPending: deleteAccountIsPending } = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['accounts'],
      });

      push({
        title: '계정 정보 삭제',
        subtitle: '삭제를 성공했습니다.',
      });
    },
    onError: (error) => {
      push({
        title: '계정 정보 삭제',
        subtitle: error.message ?? '삭제를 실패했습니다.',
        level: 'warning',
      });
    },
  });

  /**
   * 'Edit' button click event handler.
   */
  const onEditClick = async () => {
    navigate(`${RoutePaths.AccountList}/${account.id}`);
  };

  /**
   * 'Delete' button click event handler.
   */
  const onDeleteClick = async () => {
    setDeleteAccountId(account.id);
  };

  /**
   * 'Title' Field click event handler.
   */
  const onTitleClick = async () => {
    setIsTitleVisible((current) => !current);

    await sleep(EVERY_SECOND * 5);

    if (!isTitleVisible) setIsTitleVisible(false);
  };

  /**
   * 'EmailOrName' Field click event handler.
   */
  const onEmailOrNameClick = async () => {
    setIsEmailOrNameVisible((current) => !current);

    await sleep(EVERY_SECOND * 5);

    if (!isEmailOrNameVisible) setIsEmailOrNameVisible(false);
  };

  /**
   * 'Password' Field click event handler.
   */
  const onPasswordClick = async () => {
    setPasswordVisible((current) => !current);

    await sleep(EVERY_SECOND * 5);

    if (!isPasswordVisible) setPasswordVisible(false);
  };

  /**
   * 'Description' Field click event handler.
   */
  const onDescriptionClick = async () => {
    setDescriptionVisible((current) => !current);

    await sleep(EVERY_SECOND * 5);

    if (!isDescriptionVisible) setDescriptionVisible(false);
  };

  return (
    <>
      <li className="border-pixel p-4 space-y-4 divide-y divide-gray-400 rounded-sm">
        <section className="flex justify-between gap-4">
          <h2 className="text-xl tracking-wider truncate">
            <button onClick={onTitleClick}>
              {isTitleVisible ? account.title : maskingCenter(account.title)}
            </button>
          </h2>

          <div className="flex items-center gap-2 shrink-0">
            <button onClick={onEditClick}>
              <EditIcon />
            </button>

            <button onClick={onDeleteClick} disabled={deleteAccountIsPending}>
              <DeleteIcon />
            </button>
          </div>
        </section>

        <section className="flex items-center gap-4 pt-4 flex-wrap">
          <div className="flex gap-2 items-center">
            <IdentifyIcon />
            <button className="truncate" onClick={onEmailOrNameClick}>
              <span>
                {isEmailOrNameVisible ? account.emailOrName : masking(account.emailOrName)}
              </span>
            </button>
          </div>

          <div className="flex gap-2 items-center">
            <KeyIcon />
            <button className="truncate" onClick={onPasswordClick}>
              <span>{isPasswordVisible ? account.password : masking(account.password)}</span>
            </button>
          </div>
        </section>

        {account.description ? (
          <section className="pt-4">
            <button className="text-left" onClick={onDescriptionClick}>
              <small className="text-gray-500 whitespace-pre-line tracking-wider">
                {isDescriptionVisible ? account.description : masking(account.description)}
              </small>
            </button>
          </section>
        ) : null}
      </li>

      <Portal portalId="modal">
        {deleteAccountId ? (
          <ConfirmModal
            title="계정 정보 삭제"
            content="정말 해당 계정 정보를 삭제하시겠어요?"
            isPending={deleteAccountIsPending}
            onConfirm={async () => {
              await deleteAccountMutate({ id: deleteAccountId });

              setDeleteAccountId(undefined);
            }}
            onClose={() => setDeleteAccountId(undefined)}
            onCancel={() => setDeleteAccountId(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default AccountCard;
