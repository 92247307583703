import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { Menu } from 'lucide-react';

import { getWorkingTime } from '@/shared/api/graphql/members/services';
import LogoImageDark from '@/shared/assets/images/vvorkmon_logo_dark.png';
import LogoImageLight from '@/shared/assets/images/vvorkmon_logo_light.png';
import { RoutePaths } from '@/shared/constants/routes';
import { cn } from '@/shared/libs/style.lib';
import { isSidebarVisible } from '@/shared/states/sidebar.state';

import { useDarkModeStore } from '@/hooks/states/useDarkMode';

import DarkModer from '@/components/DarkModer';
import LeftTime from '@/components/LeftTime';

const Header = () => {
  const { isDark } = useDarkModeStore();

  const { data: workingTimeData } = useQuery({
    queryKey: ['workingTime'],
    queryFn: getWorkingTime,
  });

  return (
    <header className="sticky top-0 w-full flex py-3 items-center justify-between px-3 border-b z-10">
      <Link className={cn('heading no-link')} to={RoutePaths.Home}>
        <img src={isDark ? LogoImageDark : LogoImageLight} alt="logo image" loading="lazy" />
      </Link>

      <div className="flex items-center gap-5">
        {/* Left working time */}
        {workingTimeData?.isDisplayEndTime && <LeftTime className="hidden min-[410px]:flex" />}

        <DarkModer />
        <button
          onClick={() => {
            isSidebarVisible.value = !isSidebarVisible.value;
          }}
        >
          <Menu />
        </button>
      </div>
    </header>
  );
};

export default Header;
