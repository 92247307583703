import { useState } from 'react';
import { Link } from 'react-router-dom';

import ProjectItems from '@/features/projects/ProjectItems';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { deleteProject } from '@/shared/api/graphql/projects/services/delete-project.service';
import { getProjects } from '@/shared/api/graphql/projects/services/get-projects.service';
import { RoutePaths } from '@/shared/constants/routes';

import { usePopupStore } from '@/hooks/states/usePopupStore';

import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import QuestionIcon from '@/components/icons/QuestionIcon';
import Loader from '@/components/Loader';
import Portal from '@/components/Portal';

const ProjectList = () => {
  const { push } = usePopupStore();
  const queryClient = useQueryClient();

  const [deleteProjectId, setDeleteProjectId] = useState<number | undefined>();

  // Get projects query.
  const { data: projectsData, isLoading: projectsIsLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: getProjects,
  });

  // Delete project mutation.
  const { mutateAsync: deleteProjectMutate, isPending: deleteProjectIsPending } = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      push({ title: '프로젝트 삭제', subtitle: '정상적으로 삭제되었습니다.' });

      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error) => {
      push({ title: '프로젝트 삭제', subtitle: error.message, level: 'warning' });
    },
  });

  const onDeleteClick = (projectId: number) => {
    setDeleteProjectId(projectId);
  };

  return (
    <>
      <section className="space-y-2">
        <div className="flex items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <h1 className="font-bold tracking-wider">프로젝트 목록</h1>
          </div>

          <Link className="no-link" to={RoutePaths.ProjectCreate}>
            <Button>추가</Button>
          </Link>
        </div>

        <div className="flex items-stretch gap-2">
          <QuestionIcon />
          <p className="text-gray-500 font-light text-xs">
            프로젝트를 생성하고, 작업을 추가하세요! 조금 더 효율적으로 일정을 관리할 수 있어요.
          </p>
        </div>
      </section>

      <section>
        {projectsIsLoading ? (
          <Loader />
        ) : (
          <ProjectItems
            disabled={deleteProjectIsPending}
            items={projectsData}
            onDeleteClick={onDeleteClick}
          />
        )}
      </section>

      <Portal portalId="modal">
        {deleteProjectId ? (
          <ConfirmModal
            title="프로젝트 삭제"
            content={
              deleteProjectIsPending
                ? '프로젝트를 삭제하고 있습니다.'
                : '해당 프로젝트를 삭제하시겠어요?'
            }
            isPending={deleteProjectIsPending}
            onConfirm={async () => {
              await deleteProjectMutate({ id: deleteProjectId });
              setDeleteProjectId(undefined);
            }}
            onClose={() => setDeleteProjectId(undefined)}
            onCancel={() => setDeleteProjectId(undefined)}
          />
        ) : null}
      </Portal>
    </>
  );
};

export default ProjectList;
